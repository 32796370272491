<template>
    <div>
        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="table.total_records"
            :isLoading.sync="table.isLoading" :pagination-options="{
            enabled: true,
            dropdownAllowAll: false,
            perPageDropdown: [10, 25, 50, 100],
          }" :columns="table.columns" :rows="table.rows">
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'action'">
                    <a v-if="props.row.status == 'pending' && props.row.mode == 'topup' && props.row.checkout_url" :href="props.row.checkout_url" class="btn btn-primary px15" style="margin: 5px" target="_blank">
                        Bayar
                    </a>
                    <button  v-if="props.row.mode == 'pay'" v-on:click="lihatPDF(props.row)">Lihat Invoice  </button>&nbsp;
                    <button  v-if="props.row.mode == 'pay'" v-on:click="bikinPDF(props.row)">Download Invoice  </button>
                </span>
                <span v-else-if="props.column.field == 'created_at'">
                    {{ convertTimezone(props.formattedRow[props.column.field]) }}
                </span>
                <span v-else-if="props.column.field == 'keterangan'">
                    <span v-if="props.row.mode == 'topup'" class="badge badge-success">Top Up ({{ props.row.method }})</span>
                    <span v-if="props.row.mode == 'pay'" class="badge badge-danger">Pembelian Paket {{ props.row.metadata}}</span>
                    <span v-if="props.row.mode == 'pay_referral'" class="badge badge-success">Pembelian Paket {{ props.row.metadata}} </span>
                    <span v-if="props.row.mode == 'referral'" class="badge badge-success">Referral</span>
                    <span v-if="props.row.mode == 'withdraw'" class="badge badge-danger">Withdraw</span>
                </span>
                <span v-else-if="props.column.field == 'nominal'">
                    {{ formatRupiah(props.formattedRow[props.column.field]) }}
                </span>
                <span v-else-if="props.column.field == 'status'">
                    <span v-if="props.row.status == 'success'" class="badge badge-success">Sukses</span>
                    <span v-if="props.row.status == 'pending'" class="badge badge-warning text-white">Pending</span>
                    <span v-if="props.row.status == 'failed'" class="badge badge-danger text-white">Gagal</span>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
        </vue-good-table>
        <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1400"
        filename="invoice_starsender"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
 
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
    >
        <section slot="pdf-content">
            <div style="margin: 40px 40px">
			<table style='width:100%'>
			<tr style="border-bottom:1px solid #CCC;padding-bottom:50px">
			<td style="padding-bottom:50px" ><img height='100px' width='200px' src="https://app.starsender.online/img/starsender.327c5d9d.webp" /></td>
			<td align='right' colspan='3'><span style='font-size:16pt;color:#585c5e'> Starsender</span>
			<p class='ukuranHuruf12'>Villa ciomas indah blok h3 no.6<br />
			<span style='color:#67a3fd'>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="14" height="14">
<path fill='#585c5e' d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg> 081319227392</span><br />
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="14" height="14">
			<path fill='#585c5e' d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg><span style='color:#67a3fd'> starfield.id@gmail.com</span></p>
			</td>
			</tr>
			<tr>
			<td rowspan='3' style="padding:15px 0;font-size:16pt;color:#585c5e;width:60%">
			<p >{{ dataUser.name }}</p>
			<p class='ukuranHuruf'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="14" height="14"><path fill='#585c5e' d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg> {{ dataUser.email }}</p>
			<p class='ukuranHuruf'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="14" height="14"><path fill='#585c5e' d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg> {{ dataUser.phone }}</p>
			</td>
			<td class='ukuran16Tebal batasBawah'>No. Invoice</td><td class="ukuranHuruf batasBawah" >:</td><td align='right' class="ukuranHuruf batasBawah">{{dataTransTerpilih.noInvoice}}</td>
			</tr>
			<tr >
			<td class='ukuran16Tebal batasBawah'>Tanggal Order</td><td class="ukuranHuruf batasBawah" >:</td><td align='right' class="ukuranHuruf batasBawah">{{dataTransTerpilih.tglBeli}}</td>
			</tr>
			<tr class='batasBawah'>
			<td class='ukuran16Tebal'>Status</td><td class="ukuranHuruf " >:</td><td align='right' class="ukuranHuruf "><span v-if="dataTransTerpilih.status == 'success'"><button class='status'>LUNAS</button></span><span v-else><button class='status'>BELUM LUNAS</button></span></td>
			</tr>
			</table>
			<table style='width:100%'>
			<tr class='batasBawah'>
			<th>#</th><th>Item</th><th>Qty</th><th style="text-align:right">Harga</th><th style="text-align:right">Subtotal</th>
			</tr>
			<tr class='batasBawah'>
			<td style="width:5%">1</td><td style="width:40%">{{dataTransTerpilih.metadata}}</td><td style="width:5%">1</td><td style="width:20%;text-align:right">{{dataTransTerpilih.total}}</td><td style="width:30%;text-align:right">{{dataTransTerpilih.total}}</td>
			</tr>
			<tr>
			<td colspan='3'></td><td style="text-align:right"><b>Total</b></td><td style="text-align:right"><span class='ukuranHuruf14Tebal' >{{dataTransTerpilih.total}}</span></td>
			</tr>
			</table>
			<b>Terbilang:</b> (<i>{{dataTransTerpilih.terbilang}}</i>)
			<hr class='baru' />
			<p style='font-size:16pt;color:#585c5e'>Informasi Pembayaran</p>
			<p>Silahkan selesaikan pembayaran ke:</p>
			
			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 64 64" enable-background="new 0 0 64 64"><path d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50
	l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z" fill="#43a047"/></svg>
{{bayar.method}}
			<p v-if="bayar.checkout_url">URL : {{bayar.checkout_url}}</p>
			
			
			</div>
        </section>
    </vue-html2pdf>
    <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="invoice_starsender"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
 
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2PdfView"
    >
        <section slot="pdf-content">
            <div style="margin: 40px 40px">
			<table style='width:100%'>
			<tr style="border-bottom:1px solid #CCC;padding-bottom:50px">
			<td style="padding-bottom:50px" ><img height='100px' width='200px' src="http://localhost:8080/img/starsender.327c5d9d.webp" /></td>
			<td align='right' colspan='3'><span style='font-size:16pt;color:#585c5e'> Starsender</span>
			<p class='ukuranHuruf12'>Villa ciomas indah blok h3 no.6<br />
			<span style='color:#67a3fd'>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="14" height="14">
<path fill='#585c5e' d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg> 081319227392</span><br />
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="14" height="14">
			<path fill='#585c5e' d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg><span style='color:#67a3fd'> starfield.id@gmail.com</span></p>
			</td>
			</tr>
			<tr>
			<td rowspan='3' style="padding:15px 0;font-size:16pt;color:#585c5e;width:60%">
			<p >{{ dataUser.name }}</p>
			<p class='ukuranHuruf'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="14" height="14"><path fill='#585c5e' d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg> {{ dataUser.email }}</p>
			<p class='ukuranHuruf'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="14" height="14"><path fill='#585c5e' d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg> {{ dataUser.phone }}</p>
			</td>
			<td class='ukuran16Tebal batasBawah'>No. Invoice</td><td class="ukuranHuruf batasBawah" >:</td><td align='right' class="ukuranHuruf batasBawah">{{dataTransTerpilih.noInvoice}}</td>
			</tr>
			<tr >
			<td class='ukuran16Tebal batasBawah'>Tanggal Order</td><td class="ukuranHuruf batasBawah" >:</td><td align='right' class="ukuranHuruf batasBawah">{{dataTransTerpilih.tglBeli}}</td>
			</tr>
			<tr class='batasBawah'>
			<td class='ukuran16Tebal'>Status</td><td class="ukuranHuruf " >:</td><td align='right' class="ukuranHuruf "><span v-if="dataTransTerpilih.status == 'success'"><button class='status'>LUNAS</button></span><span v-else><button class='status'>BELUM LUNAS</button></span></td>
			</tr>
			</table>
			<table style='width:100%'>
			<tr class='batasBawah'>
			<th>#</th><th>Item</th><th>Qty</th><th style="text-align:right">Harga</th><th style="text-align:right">Subtotal</th>
			</tr>
			<tr class='batasBawah'>
			<td style="width:5%">1</td><td style="width:40%">{{dataTransTerpilih.metadata}}</td><td style="width:5%">1</td><td style="width:20%;text-align:right">{{dataTransTerpilih.total}}</td><td style="width:30%;text-align:right">{{dataTransTerpilih.total}}</td>
			</tr>
			<tr>
			<td colspan='3'></td><td style="text-align:right"><b>Total</b></td><td style="text-align:right"><span class='ukuranHuruf14Tebal' >{{dataTransTerpilih.total}}</span></td>
			</tr>
			</table>
			<b>Terbilang:</b> (<i>{{dataTransTerpilih.terbilang}}</i>)
			<hr class='baru' />
			<p style='font-size:16pt;color:#585c5e'>Informasi Pembayaran</p>
			<p>Silahkan selesaikan pembayaran ke:</p>
			
			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 64 64" enable-background="new 0 0 64 64"><path d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50
	l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z" fill="#43a047"/></svg>
{{bayar.method}}
			<p v-if="bayar.checkout_url">URL : {{bayar.checkout_url}}</p>
			
			
			
			</div>
        </section>
    </vue-html2pdf>
    </div>
</template>

<script>
    import moment from 'moment'
    import VueHtml2pdf from 'vue-html2pdf'
	import terbilang from 'terbilang-js'

    export default {
        components: {
			VueHtml2pdf
			
        },
        data() {
            return {
                table: {
                    total_records: 100,
                    isLoading: true,
                    columns: [{
                            label: "Tanggal Transaksi",
                            field: "created_at",
                            filterOptions: {
                                enabled: false,
                            },
                            sortable: false,
                            width: "20%",
                        },
                        {
                            label: "Keterangan",
                            field: "keterangan",
                            filterOptions: {
                                enabled: false,
                            },
                            sortable: false,
                            width: "20%",
                        },
                        {
                            label: "Nominal",
                            field: "nominal",
                            filterOptions: {
                                enabled: false,
                            },
                            sortable: false,
                            width: "20%",
                        },
                        {
                            label: "Status",
                            field: "status",
                            filterOptions: {
                                enabled: false,
                            },
                            sortable: false,
                            width: "20%",
                        },
                        {
                            label: "Action",
                            field: "action",
                            sortable: false,
                            width: "20%",
                        },
                    ],
                    rows: [],
                    serverParams: {
                        columnFilters: {},
                        sort: [{
                            field: "",
                            type: "",
                        }, ],
                        page: 1,
                        limit: 10,
                    },
                },
                dataUser:{},
				dataTransTerpilih:{},
                bayar:{},
            };
        },
        mounted() {
            this.fn = () => {
                this.loadItems();
            }
            this.$root.$on("refresh_table", this.fn);
            this.loadItems();
        },
        beforeDestroy() {
            this.$root.$off("refresh_table", this.fn);
        },
        methods: {
            bikinPDF(dataTrans){
				this.dataTransTerpilih = dataTrans
				let tglBeli = new Date(this.dataTransTerpilih.created_at)
				let bulan = tglBeli.getMonth()+1
				let angkaBulan = (bulan < 10) ? '0'+bulan : bulan;
				let tahun = tglBeli.getFullYear()
				this.dataTransTerpilih.noInvoice = this.dataTransTerpilih.id+'/INV/'+angkaBulan+'/'+tahun
				this.dataTransTerpilih.tglBeli = moment(tglBeli).format("DD MMMM YYYY")
				this.dataTransTerpilih.total = 'Rp. '+this.dataTransTerpilih.nominal.toLocaleString()
				this.dataTransTerpilih.terbilang = terbilang(this.dataTransTerpilih.nominal)
				
				this.$refs.html2Pdf.generatePdf()
				
			},
			lihatPDF(dataTrans){
				
				this.dataTransTerpilih = dataTrans
				let tglBeli = new Date(this.dataTransTerpilih.created_at)
				let bulan = tglBeli.getMonth()+1
				let angkaBulan = (bulan < 10) ? '0'+bulan : bulan;
				let tahun = tglBeli.getFullYear()
				this.dataTransTerpilih.noInvoice = this.dataTransTerpilih.id+'/INV/'+angkaBulan+'/'+tahun
				this.dataTransTerpilih.tglBeli = moment(tglBeli).format("DD MMMM YYYY")
				this.dataTransTerpilih.total = 'Rp. '+this.dataTransTerpilih.nominal.toLocaleString()
				this.dataTransTerpilih.terbilang = terbilang(this.dataTransTerpilih.nominal)
				
				this.$refs.html2PdfView.generatePdf()
				
			},
            formatRupiah(numb) {
                const format = numb.toString().split('').reverse().join('');
                const convert = format.match(/\d{1,3}/g);
                const rupiah = 'Rp ' + convert.join('.').split('').reverse().join('')
                return rupiah
            },
            convertTimezone(utc) {
                return moment.unix(utc / 1000).format("YYYY-MM-DD HH:mm:ss")
            },
            updateParams(newProps) {
                this.table.serverParams = Object.assign({},
                    this.table.serverParams,
                    newProps
                );
            },
            onPageChange(params) {
                this.updateParams({
                    page: params.currentPage
                });
                this.loadItems();
            },
            onPerPageChange(params) {
                this.updateParams({
                    limit: params.currentPerPage
                });
                this.loadItems();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params.sortType,
                        field: this.table.columns[params.columnIndex].field,
                    }, ],
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            async loadItems() {
                this.table.isLoading = true
                this.dataUser = this.$store.getters.user
                try {
                    let response = await this.getFromServer(this.table.serverParams);
                    this.table.total_records = response.data.data.total_records;
                    this.table.rows = response.data.data.transactions;
                    for (let i = 0; i < this.table.rows.length; i++) {
						if(this.table.rows[i].mode=='topup'){
							this.bayar.method = this.table.rows[i].method
							this.bayar.checkout_url = this.table.rows[i].checkout_url
						}
					}
                } catch (err) {
                    this.$toast.error("Too many request");
                }
                this.table.isLoading = false
            },
            async getFromServer(param) {
                return await this.$store.dispatch("transaction_list", param);
            },
        },
    };
</script>

<style scoped>
    .margin5 {
        margin: 5px;
    }
    .ukuranHuruf{
		font-size:12pt
	}
	.ukuranHuruf13{
		font-size:13pt
	}
	.ukuranHuruf14Tebal{
		font-size:17pt;
		font-weight:bold;
	}
	.ukuranHuruf12{
		font-size:12pt
	}
	.ukuran16Tebal{
		font-size:12pt;
		font-weight:bold
	}
	.status{
		background:seagreen;
		color:white;
		border-radius:5px;
		outline: none; 
		border: 0;
	}
	
	.batasBawah{
		border-bottom:1px solid #ccc;
	}
	.baru{border:1px solid #ccc;}
	.st0{fill:seagreen;margin-left:0;padding-left:0}
	.st1{fill:none;stroke:#333333;stroke-width:8;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}

</style>